import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'


const BlogList = () => (
  <StaticQuery
    query={BLOG_POST_ARCHIVE_QUERY}
    render={({allMarkdownRemark}) => (
      <Container>
        <Row style={{ marginTop: '3rem' }}>
          <Col>
            <h1>Blog</h1>
            <hr style={{ marginBottom: '2rem' }} />
            {
              allMarkdownRemark.edges.map((edge, index) => {
                return(
                  <React.Fragment key={index}>
                    <h2>{edge.node.frontmatter.title}</h2>
                    <p className="date">
                      Posted by {edge.node.frontmatter.author} on {edge.node.frontmatter.date}
                    </p>
                    <p style={{ marginBottom: '2rem' }}>
                      {edge.node.excerpt}
                    </p>
                    <div className="d-flex">
                      <div className="ml-auto">
                        <Link
                          to={`/blog/${edge.node.frontmatter.title.replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()}`}
                        >
                          <Button
                            variant="primary"
                            style={{
                              width: '125px',
                              marginBottom: '2rem'
                            }}
                          >
                            Read more
                          </Button>
                        </Link>
                      </div>
                    </div>
                    <hr style={{ marginBottom: '2rem' }} />
                  </React.Fragment>
                )
              })
            }
          </Col>
        </Row>
      </Container>
    )}
  />
)

export default BlogList


const BLOG_POST_ARCHIVE_QUERY = graphql`
  query BlogPostArchive {
    allMarkdownRemark (
      filter: {frontmatter: { page: {eq: "blog-post"}}}
      sort: {order: DESC, fields: [frontmatter___date]}
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            title
            author
            date(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
  }
`
