import React from 'react'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import BlogList from  '../components/page-sections/blog/blog-list'
import ScheduleConsultation from '../components/page-sections/schedule-consultation'


const BlogListPage = (props) => (
  <Layout location={props.location}>
    <SEO title="G Jones for Hormones" />
    <BlogList />
    <ScheduleConsultation />
  </Layout>
)

export default BlogListPage
